import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TacticalImg from "../components/playerImage"
import AffPes from "../components/pes2021cta"

const bestPlayers = () => {
  return (
    <Layout>
      <SEO
        title="Best Silver Ball Players in PES 2021"
        description="Find out who the best Silver Ball players are in PES 2021 myClub."
      />
      <div className="container">
        <h1>The best Silver Ball Players in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/best-players-ball-type/">Best Players</Link>
          </li>
          <li>Silver Ball Players</li>
        </ul>
        <p>
          There are many promising Silver Ball Players in PES 2021 - some can
          achieve very high ratings when fully trained, sometimes even
          surpassing Black Ball players. This is especially important when it's
          time to renew their contracts, and it can be very satisfying to train
          them.
        </p>
        <p>
          The best part? You can get many of these players for free by combining
          Free Scouts.
        </p>
        <p>
          In my descriptions you will often find me writing about{" "}
          <Link to="/player-attributes/">Player Attributes</Link>,{" "}
          <Link to="/player-skills/">Player Skills</Link>, and{" "}
          <Link to="/playing-styles/">Playing Styles</Link>, so have a look at
          those sections should you have any doubts.
        </p>
      </div>
      <AffPes />
      <div className="container">
        <h3 className="bullet">Paul Bernardoni (Angers)</h3>
        <TacticalImg
          src={`2021/players/p-bernardoni.jpg`}
          alt={`Paul Bernardoni in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>GK
          <br />
          <strong>OVR / Max: </strong>79 / 86
          <br />
          <strong>Playing Style: </strong>Offensive Goalkeeper
          <br />
        </p>
        <p>
          A tall, competent Goalkeeper with decent Conditioning (6/8), good
          overall goalkeeping stats and the sought out "Low Punt" + "Long Throw"
          skills.
        </p>
        <p>
          One cool thing about Bernardoni is that you can train him quickly with
          free scouts - all you need is "French League", " GK Catching", and
          "Left Foot", averaging 3 stars.
        </p>
        <h3 className="bullet">Ozan Kabak (FC Schalke 04)</h3>
        <TacticalImg
          src={`2021/players/o-kabak.jpg`}
          alt={`Ozan Kabak in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CB
          <br />
          <strong>OVR / Max: </strong>79 / 92
          <br />
          <strong>Playing Style: </strong>Build Up
          <br />
        </p>
        <p>
          A quick, aggressive defender who excels at physical stats, most
          notably jumping. Kabak is good at heading and his 6/8 conditioning
          means he will be willing to play on most games.
        </p>
        <p>
          <strong>Alternative: </strong>have a look at M. Demiral (Juventus) - a
          very promising CB, but that has the "Destroyer" playing style instead.
        </p>
        <h3 className="bullet">William Saliba (Arsenal)</h3>
        <TacticalImg
          src={`2021/players/w-saliba.jpg`}
          alt={`William Saliba in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CB
          <br />
          <strong>OVR / Max: </strong>76 / 89
          <br />
          <strong>Playing Style: </strong>Build Up
          <br />
        </p>
        <p>
          This youngster from Arsenal has some very nice overall defending stats
          and good conditioning (6/8), just like O. Kabak. They share the same
          relevant Player Skills.
        </p>
        <p>
          <strong>Alternative: </strong>E. Tapsoba (Bayer Leverkusen) looks like
          a decent alternative as well.
        </p>
        <h3 className="bullet">Florentino Luís (SL Benfica)</h3>
        <TacticalImg
          src={`2021/players/florentino-luis.jpg`}
          alt={`Florentino Luís in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>DMF
          <br />
          <strong>OVR / Max: </strong>76 / 88
          <br />
          <strong>Playing Style: </strong>Anchor Man
          <br />
        </p>
        <p>
          This promising defensive midfielder from Benfica is on his way to
          Monaco, but until 22/Oct you can get him with 100% certainty by
          combining the following 3 scouts: "Portuguese League", "DMF" and
          "Defensive Awareness".
        </p>
        <p>
          If W. Barrios is often regarded as a poor man's Patrick Vieira, than
          Florentino is a poor man's Barrios (at least, for now). With some
          great overall defending/physical stats for an Anchor Man, he is a
          great option to recover the ball throughout the whole game, since his
          Stamina won't let you down.
        </p>
        <h3 className="bullet">Weston McKennie (Juventus)</h3>
        <TacticalImg
          src={`2021/players/w-mckennie.jpg`}
          alt={`Weston McKennie in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CMF
          <br />
          <strong>OVR / Max: </strong>77 / 92
          <br />
          <strong>Playing Style: </strong>Box-to-Box
          <br />
        </p>
        <p>
          McKennie looks like a very solid option for the midfield. With some
          great stats overall and the "Fighting Spirit" skill, he will run
          non-stop, which is what you want for a Box-to-Box Midfielder.
        </p>
        <p>
          He can play anywhere in the midfield, but his speed and physical stats
          make him a nice defensive option. Good conditioning (6/8) also ensures
          you can use him on most games.
        </p>
        <p>
          <strong>Alternative: </strong>you should consider E. Camavinga
          (Rennes) as well. He has some great attributes overall for this
          position, but his playing style can be a problem (Anchor Man) as he
          won't go forward often.
        </p>
        <h3 className="bullet">Phil Foden (Manchester City)</h3>
        <TacticalImg
          src={`2021/players/p-foden.jpg`}
          alt={`Phil Foden in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>CMF
          <br />
          <strong>OVR / Max: </strong>78 / 89
          <br />
          <strong>Playing Style: </strong>Creative Playmaker
          <br />
        </p>
        <p>
          Phil Foden is a talented midfielder to have, especially if you enjoy
          players with a complete set of passing skills. At 20 years of age,
          Foden has many levels to keep you busy.
        </p>
        <p>
          He possesses good dribbling stats, but apart from the "Cut Behind &
          Turn" skill, don't expect to pull off fancy tricks with him. I feel he
          is better at passing, so he can be a good solution if you enjoy
          build-up plays.
        </p>
        <p>
          <strong>Alternative: </strong>have a look at D. Kulusevski (Juventus),
          who can play in the middle or on the wing.
        </p>
        <h3 className="bullet">Takefusa Kubo (Villarreal CF)</h3>
        <TacticalImg
          src={`2021/players/t-kubo.jpg`}
          alt={`Takefusa Kubo in PES 2020 myClub`}
        />
        <p>
          <strong>Position: </strong>RMF
          <br />
          <strong>OVR / Max: </strong>75 / 89
          <br />
          <strong>Playing Style: </strong>Creative Playmaker
          <br />
        </p>
        <p>
          Kubo was one of my favorite bronze balls last year, and in PES 2021 he
          has been promoted to a Silver Ball player. He feels like a poor man's
          Messi whenever I use him (that's a compliment!).
        </p>
        <p>
          Kubo has many useful skills, such as "Step on Skill Control" for quick
          turns and in my opinion the best dribbling skill in PES, the "Double
          Touch". In my opinion, he is better suited as an AMF (where that
          "Creative Playmaker" playing style shines); I'm not a fan of using him
          on the right.
        </p>
        <p>
          He does have a downside: his poor form shows he won't be very
          consistent on C Live Condition weeks. His weak foot was never a
          problem to me.
        </p>
        <p>
          Kubo is still very young, so if you have the will and discipline to
          train him all the way to the max, his overall rating will reach 89,
          highlighting his great dribbling stats.
        </p>
        <p>
          You can scout him for free by combining "Spanish League",
          "Asia-Oceania", and "RMF", averaging 3 stars.
        </p>
        <h3 className="bullet">Jonathan David (Lille)</h3>
        <TacticalImg
          src={`2021/players/j-david.jpg`}
          alt={`Jonathan David in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>AMF
          <br />
          <strong>OVR / Max: </strong>79 / 92
          <br />
          <strong>Playing Style: </strong>Hole Player
          <br />
        </p>
        <p>
          Jonathan David looks like an absolute superstar for a Silver Ball
          player. With many different useful skills, such as "One-touch Pass",
          "First-time Shot" or the "Double Touch", he can pass, shoot or
          dribble.
        </p>
        <p>
          You can use him as an AMF (my recommended position, with the best
          Playing Style for that role) but you can also use him as a CF - he has
          very good finishing and if you like pacey forwards he will be your cup
          of tea.
        </p>
        <p>
          "Speed" is actually his strongest attribute - 94 Speed and 97
          Acceleration when maxed - and his 6/8 Conditioning won't let you down
          for most matches either.
        </p>
        <p>
          You can scout this player by using only free scouts (100% chance) -
          French League / N/S American / AMF - which will come in handy to make
          sure you reach the last of his 50 levels.
        </p>
        <h3 className="bullet">Ismaïla Sarr (Watford)</h3>
        <TacticalImg
          src={`2021/players/i-sarr.jpg`}
          alt={`Ismaïla Sarr in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>RMF
          <br />
          <strong>OVR / Max: </strong>79 / 88
          <br />
          <strong>Playing Style: </strong>Prolific Winger
          <br />
        </p>
        <p>
          Sarr looks like one of the best wingers in the game. He can play on
          both wings (as a midfielder or as a forward), has insane speed and
          good dribbling stats. His "Super-sub" skill is also something to take
          under consideration.
        </p>
        <p>
          You can try to get Sarr with just free scouts (50% chance) by
          combining "English League" or "1.85cm and more" + Africa + RMF.
        </p>
        <p>
          <strong>Alternatives: </strong>There are many quality wingers who
          happen to be Silver Ball players in PES 2021. Have a look at H.
          Onyekuru (Monaco), C. Hudson-Odoi (Chelsea), and Diaby (Bayer
          Leverkusen) - all have great pace and dribbling stats, so it will be a
          matter of their Live Condition rating for that week.
        </p>
        <p>
          H. Onyekuru was featured in my list last year and is a really fun
          player.
        </p>
        <h3 className="bullet">Mason Greenwood (Manchester United)</h3>
        <TacticalImg
          src={`2021/players/m-greenwood.jpg`}
          alt={`M. Greenwood in PES 2021 myClub`}
        />
        <p>
          <strong>Position: </strong>RWF
          <br />
          <strong>OVR / Max: </strong>78 / 91
          <br />
          <strong>Playing Style: </strong>Goal Poacher
          <br />
        </p>
        <p>
          Ole Gunnar Solskjaer has praised Greenwood's natural finishing in the
          past, and you will probably agree after playing a couple of games with
          this boy.
        </p>
        <p>
          You won't find many players with 4/4 on both "Weak Foot Usage" and
          "Weak Foot Accuracy"; this means that while controlling Greenwood, you
          won't need to think about the best foot to finalize that play. I
          wouldn't use him on the wing, but as a CF.
        </p>
        <p>
          Speed and Balance are his main assets, but he has very balanced stats
          when it comes to shooting and dribbling, and many important skills
          such as "Chip Shot Control" when facing the keeper or "Cut Behind &
          Turn" for quick turns. He also has the "Super-sub" skill.
        </p>
        <p>
          His conditioning (5/8) is a bit worrying but he has been playing
          often, so if he scores a lot this season the arrows shouldn't be a
          problem on most weeks.
        </p>
        <p>
          You can get Greenwood with a 50% chance by combining these 3 scouts -
          "English League", "RWF" and "Finishing".
        </p>
        <p>
          <strong>Alternatives: </strong>Rafael Leão (AC Milan) and Chalov (CSKA
          Moscow) are two other Centre Forwards definitely worth a mention. Both
          are fun players to use (Leão was actually featured in my list last
          year).
        </p>
      </div>
    </Layout>
  )
}

export default bestPlayers
